import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { LuPhoneOutgoing } from "react-icons/lu";
import { Link } from 'react-router-dom';

const Inquiry = () => {
  return (
    <div>
      <div className='inquiry'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className='inquiryHeading'>
                            <span className="spans">Lets work together</span>
                            <h2>Need a successful project?</h2>
                            <Link to="/contact"><button className='btn '>Book Appointment <FaLocationArrow/></button></Link>
                           <div>
                           <p>or Call us Now <span> <LuPhoneOutgoing/> +91 883 032 2700</span></p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Inquiry
