import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Aboutus() {
  return (
    <div className='aboutus'>
        <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div>
                            <img src="./images/orbit-technology-about-us.png" className="w-100" title="Orbit Technologies About Us" alt="Orbit Technologies About Us"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 my-auto">
                        <span className="spans">About Us</span>
                        <h2>We Are a Digital Marketing and IT Infrastructure Company </h2>
                        <p>
                        At Orbit Technologies, we are passionate about propelling businesses forward in the digital realm. Specializing in comprehensive digital marketing solutions, we harness the power of SEO, Google Ads, and Social Media Marketing to elevate brands and drive meaningful growth. Our dedicated team of experts combines creativity with cutting-edge technology to deliver tailored strategies that ensure your success in the competitive online landscape. Whether you’re looking to boost your search engine visibility, maximize ROI with targeted ads, or engage audiences across social platforms, Orbit Technologies is your trusted partner in achieving digital excellence.
                         </p>
                        <Link to="/contact"><button className="btn"> Inquiry Now <FaLocationArrow/></button></Link>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Aboutus
