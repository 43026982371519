import React from 'react';

const WhatsAppButton = ({ phoneNumber, message }) => {
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <button type="button">Inquiry Now</button>
    </a>
  );
};

export default WhatsAppButton;
