import React from 'react'
import { RiDoubleQuotesR } from "react-icons/ri";
import { MdOutlineStarPurple500 } from "react-icons/md";



const Testimonilas = () => {

    return (
        <div className='testimonila'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className='testimonilaHeading'>
                            <span className="spans">Testimonials</span>
                            <h2>What Clients Say</h2>
                        </div>
                    </div>
                </div>
                <ReviewComponent />
            </div>
        </div>
    )
}

export default Testimonilas

const ReviewData = [
    {
        cName: "Devesh Mahobe",
        crName: "Owner",
        quoteIcon: <RiDoubleQuotesR />,
        description: "After using services from Pune Digital Marketing Agency, my business has grown by 25%. Their expertise and Team  support have been invaluable. Thanks for helping me achieve this success! Highly recommend their services.",
        rIcon: <MdOutlineStarPurple500 />
    },
    {
        cName: "Amol Ujagare",
        crName: "Owner",
        quoteIcon: <RiDoubleQuotesR />,
        description: "I’ve been using Pune Digital's services for my coaching business for the past three years and am very satisfied with my business growth. and they are very cooprative Highly recommend!",
        rIcon: <MdOutlineStarPurple500 />
    },
    {
        cName: "Kalyani Ghodake",
        crName: "Owner",
        quoteIcon: <RiDoubleQuotesR />,
        description: "Exceptional service and results! Pune Digital Marketing Company boosted our online presence and sales significantly. Highly recommend for digital marketing needs.",
        rIcon: <MdOutlineStarPurple500 />
    }
]
const ReviewComponent = () => {
    return (
        <div className='row'>
            {ReviewData.map((rDatas, index) => {
                return (
                    <div className="col-lg-4" key={index}>
                        <div className='testimonilaBox'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>
                                {rDatas.cName}
                                <br />
                                <small>{rDatas.crName}</small>
                            </h4>
                            <span>{rDatas.quoteIcon}</span>
                        </div>
                        <p>{rDatas.description}</p>
                        <small>
                            {Array(5).fill(<MdOutlineStarPurple500 />).map((icon, idx) => (
                                <React.Fragment key={idx}>{icon}</React.Fragment>
                            ))}
                        </small>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};