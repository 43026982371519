import React from 'react'
import Services from '../Services';
import Breadcrumb from '../Breadcrumb';

const Our_services = () => {
  return (
    <div>
      <Breadcrumb/>
      <Services/>
    </div>
  )
}

export default Our_services;
