import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './component/pages/Home';
import About from './component/pages/About';
import { Routes, Route } from 'react-router-dom';
import Our_services from './component/pages/Our_services';
import Contact from './component/pages/Contact';
import NotFoundPage from './component/pages/404page'
import Blog from './component/pages/Blog';
import BlogsData from './component/pages/BlogsData';
import FloatingChatButton from './component/FloatingChatButton';
import Career from './component/pages/Career';

function App() {
  return (
    <>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Our_services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogsData/>}/>
          <Route path="/career/" element={<Career/>}></Route>
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
        </Routes>
        
        <FloatingChatButton phoneNumber="8830322700" />
      <Footer />
    </>
  );
}

export default App;