import React from 'react';
import BlogData from '../blogData.json';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
const BlogSection = () => {
    return (
        <div>
          <div className="blog">
          <div className="container">
            <div className="row">
            <div className='col-lg-6'>
            <div className='BlogHeading'>
                                <span>Our Blogs</span>
                                <h2>Inspiring Insights from Our Latest Blogs</h2>
                            </div>
                        </div>           
            </div>
            <div className="row">
            {BlogData.map(blog => (
              <div key={blog.id} className="col-lg-4">
                <div className="blog-data">
                <img src={blog.image} alt={blog.title} />
                <div className="d-flex justify-content-between align-items-center">
                <h3>{blog.title}</h3><small className='badge rounded-pill bg-danger text-white'>{blog.category}</small>
                </div>
                
                <p>{blog.shortdescription}</p>
                <div className='read-more'>
                <Link to={`/blog/${blog.id}`}>Read More <MdOutlineKeyboardDoubleArrowRight/></Link>
                </div>
                </div>
              </div>
            ))}
          </div>
          </div>
          </div>
        </div>
      );
    };
export default BlogSection
