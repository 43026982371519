import React from 'react';
import Breadcrumb from '../Breadcrumb';
import BlogSection from '../BlogSection';


const Blog = () => {
  
  return (
    <div>
      <Breadcrumb />
      <BlogSection/>
      </div>
  );
};

export default Blog;
