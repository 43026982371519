import React from 'react';
import { useForm } from 'react-hook-form';
import Breadcrumb from '../Breadcrumb';

const CareerPage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    // Create FormData object to handle file uploads
    const formData = new FormData();
    formData.append('fullName', data.fullName);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('address', data.address);
    formData.append('profile', data.profile);
    formData.append('experience', data.experience);
    formData.append('message', data.message);
    if (data.resume[0]) {
      formData.append('resume', data.resume[0]);
    }

    try {
      const response = await fetch('http://localhost/your-php-script-path/submit.php', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      console.log(result);
      // Handle the result from the server, show a success message, etc.
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
        <Breadcrumb/>
        <div className="container my-5">
      <h1 className="mb-4">Join Our Team</h1>
      <p>We are always looking for talented individuals to join our team. Please fill out the form below to apply for a position.</p>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input
              type="text"
              className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
              id="fullName"
              placeholder="Enter your full name"
              {...register('fullName', { required: 'Full Name is required' })}
            />
            {errors.fullName && (
              <div className="invalid-feedback">
                {errors.fullName.message}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">Email ID</label>
            <input
              type="email"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              id="email"
              placeholder="Enter your email"
              {...register('email', { required: 'Email ID is required' })}
            />
            {errors.email && (
              <div className="invalid-feedback">
                {errors.email.message}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="phone" className="form-label">Phone No</label>
            <input
              type="tel"
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
              id="phone"
              placeholder="Enter your phone number"
              {...register('phone', { required: 'Phone No is required' })}
            />
            {errors.phone && (
              <div className="invalid-feedback">
                {errors.phone.message}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="address" className="form-label">Address</label>
            <input
              type="text"
              className={`form-control ${errors.address ? 'is-invalid' : ''}`}
              id="address"
              placeholder="Enter your address"
              {...register('address', { required: 'Address is required' })}
            />
            {errors.address && (
              <div className="invalid-feedback">
                {errors.address.message}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="profile" className="form-label">Profile</label>
            <select
              className={`form-select ${errors.profile ? 'is-invalid' : ''}`}
              id="profile"
              {...register('profile', { required: 'Profile selection is required' })}
            >
              <option value="">Select a profile</option>
              <option value="frontend">Frontend Developer</option>
              <option value="backend">Backend Developer</option>
              <option value="fullstack">Fullstack Developer</option>
              <option value="designer">UI/UX Designer</option>
              {/* Add more options as needed */}
            </select>
            {errors.profile && (
              <div className="invalid-feedback">
                {errors.profile.message}
              </div>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="experience" className="form-label">Experience (Years)</label>
            <select
              className="form-select"
              id="experience"
              {...register('experience', { required: 'Experience is required' })}
            >
              <option value="">Select experience</option>
              {[...Array(11).keys()].map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            {errors.experience && (
              <div className="invalid-feedback">
                {errors.experience.message}
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="resume" className="form-label">Upload Resume</label>
            <input
              type="file"
              className="form-control"
              id="resume"
              {...register('resume')}
              accept=".pdf,.doc,.docx"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            className="form-control"
            id="message"
            rows="4"
            placeholder="Enter your message"
            {...register('message')}
          ></textarea>
        </div>
        <button type="submit" className="view-more-btn">Submit Application</button>
      </form>
    </div>
    </div>
  );
};

export default CareerPage;
