import React from 'react';
import WhatsAppButton from './WhatsAppButton';

function Card(props) {


  return (
    <div className='services'>
      <div className="container">
        <div className='row'>
          <div className='col-lg-6'>
            <div className='servicesHeading'>
              <span>What we can do for you</span>
              <h2>Our IT solutions and IT Infrastructure</h2>
              <p>
                Orbit Technologies has 8 years of experience in IT infrastructure activities.
              </p>
            </div>
          </div>
        </div>
          <ItInfra/>
      </div>
    </div>
  );
}

export default Card;
const ItInfra = () => {
  
  const Data = [
    {
        "title": "CCTV",
        "description": "Share your brand with the world. We craft digital strategies and build loyal communities to generate quality business leads.",
        "inquiryMessage": "Hello sir I'm Looking For Digital Marketing Services",
        "icons": "./images/content-strategy.png"
    },
    {
        "title": "Structured Cabling",
        "description": "Our expertise in innovative UI/UX makes us Pune's leading web development company, building world-class websites that stand out.",
        "inquiryMessage": "Hello sir I'm Looking For Website Design Services",
        "icons": "./images/graphic-designer.png"
    },
    {
        "title": "IP Camera",
        "description": "Our web development team excels in creating stunning, user-friendly websites with innovative development, ensuring top performance.",
        "inquiryMessage": "Hello sir I'm Looking For Web Development Services",
        "icons": "./images/web-development.png"
    },
    {
        "title": "IT Infrastructure",
        "description": "We offer top-notch app development services, delivering innovative, user-friendly mobile applications tailored to meet your business needs and goals.",
        "inquiryMessage": "Hello sir I'm Looking For App Development Services",
        "icons": "./images/app-settings.png"
    },
    {
        "title": "EPBX Intercom",
        "description": "We offer top-notch app development services, delivering innovative, user-friendly mobile applications tailored to meet your business needs and goals.",
        "inquiryMessage": "Hello sir I'm Looking For App Development Services",
        "icons": "./images/app-settings.png"
    },
    {
        "title": "VDP",
        "description": "We offer top-notch app development services, delivering innovative, user-friendly mobile applications tailored to meet your business needs and goals.",
        "inquiryMessage": "Hello sir I'm Looking For App Development Services",
        "icons": "./images/app-settings.png"
    },
    {
        "title": "Networking Racks",
        "description": "We offer top-notch app development services, delivering innovative, user-friendly mobile applications tailored to meet your business needs and goals.",
        "inquiryMessage": "Hello sir I'm Looking For App Development Services",
        "icons": "./images/app-settings.png"
    },
    {
        "title": "Biometric Machine",
        "description": "We offer top-notch app development services, delivering innovative, user-friendly mobile applications tailored to meet your business needs and goals.",
        "inquiryMessage": "Hello sir I'm Looking For App Development Services",
        "icons": "./images/app-settings.png"
    }
]
  return (
    <div className='row'>
        {
            Data.map(
                (d,i)=>{ 

                  return(
                    <div className='col-lg-3' key={i}>
            <div className='servicesBox'>
              <img src={d.icons} alt={d.title} />
              <h3>{d.title}</h3>
              <p>{d.description}</p>
              <button>
                <WhatsAppButton phoneNumber="8830322700" message={d.inquiryMessage} />
              </button>
            </div>
          </div>
                  )

                }
            )
        }
  </div>
  )
}