import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import ServicesData from '../services.json'
import WhatsAppButton from './WhatsAppButton';


function Services() {
  return (
    <div>
      <ServicesDetails/>
    </div>
  )
}

export default Services;
function ServicesDetails(){
    return(
        <div className='services'>
            <div className="container">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='servicesHeading'>
                            <span>what we can do for you</span>
                            <h2>Services we can help you with</h2>
                            <p>
                        Orbit Technologies has 8 years experience in it infrastructure and also we are made digital marketing and social media marketing Seo and brand building activities
                        </p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {
                        ServicesData.map(
                            (sData, index)=>{
                                return(
                                    <div className='col-lg-3' key={index}>
                                        <div className='servicesBox'>
                                            <img src={sData.icons} alt={sData.title} title={sData.title}/>
                                            <h3>{sData.title}</h3>
                                            <p>{sData.description}</p>                                            
                                            <button>{sData.links} <WhatsAppButton phoneNumber="8830322700" message={sData.inquiryMessage} /><FaWhatsapp/></button>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                    
                </div>                
            </div>
        </div>
    )
}