import React, { useState } from 'react';
import FloatingLabelInput from './FloatingLabelInput'; // Import the FloatingLabelInput component
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { PiMapPinAreaFill } from "react-icons/pi";


import Breadcrumb from '../Breadcrumb';

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formValues);
  };

  return (
    <div>
    <Breadcrumb/>
    <div className="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="contact-form">
              <h1>Get a free quote now</h1>
              <form onSubmit={handleSubmit}>
                <FloatingLabelInput
                  id="name"
                  type="text"
                  placeholder="Name *"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
                <FloatingLabelInput
                  id="email"
                  type="email"
                  placeholder="Email *"
                  value={formValues.email}
                  onChange={handleChange}
                  required
                />
                <FloatingLabelInput
                  id="phone"
                  type="text"
                  placeholder="Phone *"
                  value={formValues.phone}
                  onChange={handleChange}
                  required
                />
                <div className="form-group">
                  <textarea
                    id="message"
                    className="form-control"
                    value={formValues.message}
                    onChange={handleChange}
                    rows="5" // Set the number of visible rows here
                    required
                  ></textarea>
                  <label htmlFor="message" className={`form-label ${formValues.message.length > 0 ? 'active' : ''}`}>
                    Message *
                  </label>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="col-lg-6">
            
          <div className="contact-list">
              <span><PiMapPinAreaFill/></span>
              <h2>Address</h2>
             
              <small>shop no 2, Gagan Ela, Near Palace Orchid, NIBM Road Undri, Pune - 411060</small>
            </div>
            <div className="contact-list">
              <span><LiaPhoneVolumeSolid/></span>
              <h2>Phone</h2>
              <p>Our customer care is open from Mon-Sat, 10:00 am to 6:00 pm</p>
              <small>Call us Now  +91 883 032 2700</small>
            </div>
            <div className="contact-list">
              <span><MdOutlineMarkEmailUnread/></span>
              <h2>Email</h2>
              <p>Our support team will get back to in 48-h during standard business hours.</p>
              <small>Email  contact@orbittechno.in</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactForm;
