import React, { useState } from 'react';
import { BsTranslate } from 'react-icons/bs';
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";



const Clientslider = () => {
    
    const clintImg = [
        { image: "./images/boss.png" },
        { image: "./images/nyx.png" },
        { image: "./images/plum.png" },
        { image: "./images/Hettich.png" },
        { image: "./images/keesebomer.png" },
        { image: "./images/orbit-icon.png" },
        { image: "./images/logo.png" }
    ]
    const [slider, setSlider] = useState(0)

    const slideNext = () =>{
        
        console.log(clintImg.length)
        if(clintImg.length-4 == slider) return false;
        setSlider(slider +1);
    }
    const slidePrev = () =>{
        
        if(slider==0) return false;
        setSlider(slider -1);
    }
    return (
        <div>
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                        <div className="cbtn">
                            <span onClick={slidePrev}><IoMdArrowDropright/></span>
                            <span  onClick={slideNext}><IoMdArrowDropleft/></span>
                        </div>
                    </div>
                    <div className='col-lg-12 cimgp'>
                    {
                        clintImg.map(
                            (cimg, index) => {
                                return (
                                        <div className="cimg" key={index} style={{transform:`translateX(-${slider * 100}%)`}} >
                                            <img src={cimg.image} alt="" />
                                        </div>
                                )
                            }
                        )
                    }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Clientslider
