import React from "react";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";

function Hero(){
    return(
        <div className="hero">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 my-auto">
                        <span className="spans">ORBIT TECHNOLOGY</span>
                        <h1>Elevate Your<span>Digital Presence, Reach New Heights</span></h1> 
                        <h2>Provide IT solutions and IT Infrastructure & digital Marketing</h2>
                        <p>
                            Our IT solutions and IT infrastructure services offer comprehensive support to ensure your business runs smoothly and efficiently. From setting up and maintaining robust networks to providing cutting-edge cybersecurity, we tailor our services to meet your specific needs, ensuring your operations are secure, reliable, and scalable for future growth.                        </p>
                            <p>
                            Our digital marketing services help businesses enhance their online presence and reach their target audience effectively. Utilizing the latest strategies in SEO, social media marketing, and content creation, we drive traffic to your website and convert visitors into loyal customers, boosting your brand's visibility and profitability.
                            </p>
                        <Link to="/contact"><button className="btn"> Contact Us <FaLocationArrow/></button></Link>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div>
                            <img src="./images/orbittechnologies-digital-marketing-agency.jpg" className="w-100" title="Orbit Technologies" alt="Orbit Technologies" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero;