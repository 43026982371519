import React from 'react';
import { useParams } from 'react-router-dom';
import BlogData from '../../blogData.json';
import Breadcrumb from '../Breadcrumb';

const BlogsData = () => {
    const { id } = useParams();
    const blog = BlogData.find(blog => blog.id.toString() === id);

    if (!blog) {
        return <div>Blog post not found</div>;
    }

    return (
        <div>
            <Breadcrumb />
            <div className="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details">
                                <img src={'../' + blog.image} alt={blog.title} />
                                <h2>{blog.title}</h2>
                                <small className='badge rounded-pill bg-danger text-white'>{blog.category}</small>
                                <p>{blog.date}</p>
                                <p>{blog.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogsData;
