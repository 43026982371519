import React from 'react'
import Aboutus from '../Aboutus';
import Breadcrumb from '../Breadcrumb';

const About = () => {
  return (
    <div>      
      <Breadcrumb/>
      <Aboutus />
    </div>
  )
}

export default About;
