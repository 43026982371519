import React, { useState } from 'react';
import Breadcrumb from '../Breadcrumb';

const FloatingLabelInput = ({ id, type = 'text', placeholder, value, onChange, required }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="form-group">
      <input
        id={id}
        type={type}
        className="form-control"
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(value.length > 0)}
        required={required}
      />
      <label htmlFor={id} className={`form-label ${isFocused || value.length > 0 ? 'active' : ''}`}>
        {placeholder}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
