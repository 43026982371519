import React from 'react';

const FloatingChatButton = ({ phoneNumber }) => {
  const url = `https://wa.me/${phoneNumber}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="floating-chat-button">
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="Chat with us on WhatsApp" />
    </a>
  );
};

export default FloatingChatButton;
